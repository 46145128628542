/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300&display=swap');

/* css variables */
:root {
    --primary-color: #F8A109;
    --small-text-color : #fff;
    --heading-color: #F8A109;
    --btn-primary-bg: #F8A109;
    --bt-secondry-bg: #6D4D17;
    --team-card-bg: #4D3A19;
    --link-color: #fff;
    --link-active: #F8A109;
    --card-bg: linear-gradient(
    45deg,
    rgb(77, 58, 25) 33%,
    rgb(134, 99, 38) 52%,
    rgb(160, 114, 33) 84%
    );
    --counter-color: linear-gradient(
    90deg,
    rgb(160, 114, 33) 20%,
    rgb(134, 99, 38) 38%,
    rgb(77, 58, 25) 100%
    );
    --tetimonial-bg: #866326;
    --font-name: "Roboto", sans-serif;
}

.light-theme {
--body-bg: #000;
--heading-color: #FFF;
--btn-primary-bg: #F8A109;
--counter-color: #F8A109;
--counter-section-bg: #4D3A19;
--newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
);
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
body{
    background: var(--body-bg);
    font-family: var(--font-name);
}

a{
    text-decoration: none;
    color: var(--link-color);
}

h1,h2{
    color: var(--heading-color);
    font-size: 2rem;

}

section{
    padding: 60px 0px;
}

.container {
    width: 1140px;
    padding: 30px 15px;
    margin: auto;
}
.description{
    color: var(--small-text-color);
    line-height: 30px;
    font-size: 0.9rem;
}

.subtitle{
    font-size: 1rem;
    color: var(--primary-color);
    font-weight: 400;
    margin-bottom: 20px;
}

@media only screen and (max-width:1024px) {
    .container{
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    section {
        padding: 40px 0px;
    }
}

