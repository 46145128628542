#contact{
    background:  var(--card-bg);
}

.contact__content{
    text-align: center;
    margin-bottom: 30px;
}


.contact__item-wrapper{
    margin-top: 20px;
    display:flex;
    justify-content: space-between;

}
.contact-item {
    padding: 20px 20px 20px 0px;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 10px;
}

.contact-icon {
    width: 50px;
    height: 60px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.contact-icon i{
    color: var(--primary-color);
    font-size: 2rem ;
}
    
.contact-title{
    color: #fff;
    font-weight: 700;
}


@media only screen and (max-width:768px) {
    .contact-item {
        width: 30%;
        margin-bottom: 30px; 
    }
    .contact__item-wrapper{
        flex-wrap: wrap;
    }

    .contact-icon {
        width: 10%;
    }

    .contact-item h4{
        font-size: 0.8rem;
    }
}

@media only screen and (max-width:576px) {
    .contact-item{
        width: 50%;
    }
}