.our__team{
    padding-top: 0;
}

.team__content{
    text-align: center;
    margin-bottom: 50px;
}

.team__item{
    align-items: center;
    width: 20%;
    margin-bottom: 30px;
    background: var(--team-card-bg);
}



.team__img{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    padding-top: 10px;
}

.team__img img{
    width: 100%;
    height: 180px;
    object-fit: contain;
    transform: scale(1.3);
}

.team__details{
    padding: 20px 15px;
}

.team__details h4{
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
}

.team__member-social {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 5px;
}

.team__member-social span{
    padding: 5px;
    background: var(--icon-bg);
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}

.team__wrapper{
    display: flex;
    align-items: center;
    column-gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;


}


@media screen and (max-width: 768px) {
    .team__item{
        width: 30%;
        margin-bottom: 30px;

    }

    .team__wrapper {
        flex-wrap: wrap;
        justify-content: center;

    }

    .team__content h2{
        font-size: 1.5rem;
    }

    .team__details h4{
        font-size: 1rem;
    }

    .team__details p{
        font-size: 0.8rem;
    }
}


@media screen and (max-width: 576px) {
    .team__item{
        width: 100%;
    }
}

