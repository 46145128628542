
.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
   
}

.nav__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu{
    display :flex;
    align-items:  center;
    column-gap: 2.7rem;
    list-style: none;
}

.menu__link{
    font-weight: 400;
    font-size: 0.9rem;
    cursor: pointer;
}

.menu__link:hover{
    color: var(--link-active);
}

.logo h2 {
    color: var(--primary-color);
}

.light__mode span {
    color: #fff;
    display: flex;
    column-gap: 0.4rem;
    align-items: center;
    font-size: 0.8rem;
}

.header__shrink{
    width: 100%;
    height: 70px;
    line-height: 10px;
    position:sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #4D3A19;
}

.mobile__menu{
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
    display: none;
}


/*=============== responsive ===================*/
@media only screen and (max-width:992px) {
   
    .header{
        width: 100%;
        height: 85px;
        line-height: 50px;
    }

    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(6, 11, 26, 0.868);
        z-index: 99999;
        display: none;
    }

    .menu{
        flex-direction: column;
        background: #4D3A19;
        width: 250px;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999999;
    }

    .menu__active{
        display: block;
    }

    .mobile__menu {
        display: block;
    }
}


@media only screen and (max-width: 768px){
    .header{
        width: 100%;
        height: 85px;
        line-height: 5 0px;
    }

    .mobile__menu {
        font-size: 1rem;
    }

    .logo h2{
        font-size: 1.3rem;
    }

}

@media only screen and (max-width: 768px){
    .counter__wrapper {
        flex-direction: column;
    }
    .counter__item {
        width: 100%;
        margin-bottom: 30px;
    }
}