


.hero__wrapper {
    display: flex;
    justify-content: space-between;
}

.hero__content,
.hero__img {
    width: 50%;
}

.hero__content{
    padding-top: 40px;
}
.hero__content h2{
    font-size:3rem;
    line-height: 65px;
}

.hero__content p{
    margin-top: 20px;
    font-size: 1.1rem;
}

.highlight {
    color: #F8A109;
}

.hero__btns{
    margin-top: 40px;
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.primary__btn, .secondary__btn{
    padding: 0.8rem 1.5rem;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 0.2rem;
    cursor: pointer;
}

.primary__btn {
    background: var(--bt-secondry-bg);
    border: 1px solid var(--btn-primary-bg);
}

.secondary__btn {
    background: var(--btn-primary-bg);
}



@media only screen and (max-width:992px) {
    .hero__content h2 {
        font-size: 2rem;
        line-height: 50px;
    }
    .hero__img img{
        width: 100%;
    }
}

@media only screen and (max-width:768px) {
    .hero__content h2{
        font-size: 1.5rem;
        line-height: 45px;
    }

    .hero__content p{
        font-size: 0.9rem;
    }
    
    .primary__btn,
    .secondary__btn {
        font-size: 0.8rem;
    }

    .hero__content,
    .hero__img {
        width: 100%;
    }
    .hero__img img{
        width: 100%;
    }
}

@media only screen and (max-width:600px) {
    .hero__content,
    .hero__img {
        width: 100%;
    }
    
    .hero__img img{
        width: 100%;
    }

    .hero__wrapper{
        flex-direction: column;
    }
}